#fh5co-author{
  font-family: "helveticaMediumExtended";
}

.paragraph{
  text-align: "justify";
  font-family:"helveticaMediumExtended";
  font-size:16px;
}

.partners{
  margin: auto;
  text-align: center;
  min-width:100%;
  max-width:100%;
}

.animate-box .work-grid .item {
  width:100%;
  margin:auto;
}

.paddingLeft{
  padding-left:"5%";
}

.moment{
  font-family:"helveticaMediumExtended";
  font-size:18px;
  font-weight:bold;
}

.testimony .item{
  font-family:"helveticaMediumExtended";
  font-size:14px;
}

.testimony-h3 .orange{
  color:orange;
  padding-right: 10px;
}

.testimony-h3{
  display: "none";
  font-family:"helveticaMediumExtended";
  font-size:14px;
}
