.fh5co-heading .center{
  text-align: center;
}

.fh5co-heading h1{
  font-weight: bold;
  display: "none";
}

.testimony .top-center{
  margin-top: -1em;
  text-align: center!important;
}

.center{
  margin:auto!important;
  text-align: center;
}

.animate-box h3{
  display: "none";
}

.animate-box h3 .orange{
  font-weight: bold;
  color:orange;
  font-size: larger;
  display: "none";
}

.animate-box h3 .bold{
  font-weight: bold;
  display: "none";
}

.btn-primary {
  text-align: center;
  color:white!important;
  background-color: #cabcab!important;
  border-color: #cabcab!important;
  min-width: 20%;
  min-height: 20%;
  text-transform: uppercase;
  font-size: 12px;
  font-family: 'helveticaMediumExtended'!important;
}

img .image-responsive .center{
  margin: auto;
  text-align: center;
}

.image-responsive{
  max-height: 250px;
}
