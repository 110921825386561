@import url(https://fonts.googleapis.com/css2?family=Karla:wght@200;400&display=swap);
.fh5co-heading .center{
  text-align: center;
}

.fh5co-heading h1{
  font-weight: bold;
  display: "none";
}

.testimony .top-center{
  margin-top: -1em;
  text-align: center!important;
}

.center{
  margin:auto!important;
  text-align: center;
}

.animate-box h3{
  display: "none";
}

.animate-box h3 .orange{
  font-weight: bold;
  color:orange;
  font-size: larger;
  display: "none";
}

.animate-box h3 .bold{
  font-weight: bold;
  display: "none";
}

.btn-primary {
  text-align: center;
  color:white!important;
  background-color: #cabcab!important;
  border-color: #cabcab!important;
  min-width: 20%;
  min-height: 20%;
  text-transform: uppercase;
  font-size: 12px;
  font-family: 'helveticaMediumExtended'!important;
}

img .image-responsive .center{
  margin: auto;
  text-align: center;
}

.image-responsive{
  max-height: 250px;
}

h4{
  font-family: "helveticaMediumExtended";
  margin-bottom: 25px;
}

.init{
  color:orange;
}

.successMessage{
  color:green;
}

.error{
  color:red
}

.myformlabel{
  font-family: "helveticaMediumExtended";
  font-size: 16px;
  color:black;
}

.myforminput{
  font-family: "helveticaMediumExtended";
  font-size: 16px;
  color:black;
}

.myforminput:active, .myforminput:focus{
  font-family: "helveticaMediumExtended";
  font-size: 16px;
  color:black;
  outline:"none"!important;
}

.checkboxesTitle{
  font-family: "helveticaMediumExtended";
  font-size: 16px;
  color:black;
  font-weight: bold;
}

.checkboxesGroup{
  margin-top: 25px;
}

.form-check-label{
  margin-left: 50px;
  font-family: "helveticaMediumExtended";
  font-size: 16px;
}

.myforminputtext{
  font-family: "helveticaMediumExtended";
  font-size: 16px;
  height : 5rem;
  color:"black";
}

.btnFormSend{
  margin-left:75%;
  width:25%;
  height : 5rem;
  background-color: #edebe8;
  border: 1px solid #edebe8;
  color:black;
}

@media (max-width: 768px) {
.btnFormSend
    {
      margin-left:5%;
      width:90%;
    }
}

.btn-outline-success, .btn-outline-success:hover{
  padding-top:8px;
  color:white;
  border-color:black;
  background-color: black;
}

.form-check-input:checked{
  background-color: #cabcab!important;
}

.btn-envoyer{
  font-family: "helveticaMediumExtended";
  font-size:16px;
  height : 5rem;
  color:white!important;
}

#fh5co-author{
  font-family: "helveticaMediumExtended";
}

.paragraph{
  text-align: "justify";
  font-family:"helveticaMediumExtended";
  font-size:16px;
}

.partners{
  margin: auto;
  text-align: center;
  min-width:100%;
  max-width:100%;
}

.animate-box .work-grid .item {
  width:100%;
  margin:auto;
}

.paddingLeft{
  padding-left:"5%";
}

.moment{
  font-family:"helveticaMediumExtended";
  font-size:18px;
  font-weight:bold;
}

.testimony .item{
  font-family:"helveticaMediumExtended";
  font-size:14px;
}

.testimony-h3 .orange{
  color:orange;
  padding-right: 10px;
}

.testimony-h3{
  display: "none";
  font-family:"helveticaMediumExtended";
  font-size:14px;
}

.navigation {
  position: fixed!important; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: #edebe8;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}
.brand-name {
  text-decoration: none;
  color: black;
  margin-left: 1rem;
  text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    font-family: "helveticaMediumExtended";
}
.navigation-menu {
  margin-left: auto;
}
.navigation-menu ul {
  display: flex;
  padding: 0;
}
.navigation-menu li {
  /*removes default disc bullet for li tags and applies margin to left & right side*/
  list-style-type: none;
  min-width: 150px;
  max-width: 150px;
  margin: 0 1rem;
}
.navigation-menu li a {
  /*increases the surface area of the anchor tag to span more than just the anchor text*/
  text-decoration: none;
  display: block;
  width: 100%;
  margin:auto;
}
.hamburger {
  /*removes default border on button element*/
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #edebe8;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
/*positions the icon to the right and center aligns it vertically*/
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}
.hamburger:hover {
  background-color: #edebe8;
}

/* Style The Dropdown Button */
.dropbtn {
  background-color: #edebe8;
  color: white;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  min-width: 150px;
    max-width: 150px;
    margin: 0 1rem;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #edebe8;
  width:100%;
  /*box-shadow: 0px 8px 0px 2px rgba(0,0,0,0.2);*/
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  text-decoration: none;
  display: block;
}


/* Show the dropdown menu on hover */

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
    background-color:  #edebe8;
    text-align: left;
    width:120%;
    margin-left: -20%;
}

.menu-li{
  min-width: 150px;
  max-width: 150px;
}
.menu-li a{
text-align: center;
}

@media screen and (max-width: 768px) {
  .dropdown {
    position: relative;
    display: inline-block;
  min-width:100%;
  margin:0;
  max-width: 100%;
  }
  .menu-li{
    min-width: 100%;
    max-width: 100%;
  }
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
      background-color:  grey;
      text-align: center;
      width:100%;
      margin-left: 0%;
  }
  .hamburger {
    display: block;
  }
  .navigation{
    height:60px;
  }
  .navigation-menu ul {
    display: none;
  }
  .navigation-menu ul {
    /*navigation menu is positioned to start 60px from the top of the document (which is directly below the navbar)*/
    position: absolute;
    top: 60px;
    left: 0;
    /*stacks the li tags vertically*/
    flex-direction: column;
   /*makes menu span full height and width*/
    height: calc(100vh - 77px);
    background-color: white;
    border-top: 1px solid black;
    display: none;
  }
  .navigation-menu li {
    /*centers link text and strips off margin*/
    text-align: center;
    margin: 0;
    text-decoration: none;
    min-width: 100%;
  }
  .navigation-menu li a {
    color: black;
    /*increases the surface area of the anchor tag to span the full width of the menu*/
    padding: 1.5rem 0;
    display:block;
    min-width: 100%;
  }
  .navigation-menu li:hover {
    background-color: #eee;
    min-width: 100%;
  }
  .navigation-menu.expanded ul {
    min-width: 100%;
    display: inline-flex;
    align-items: center;

  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "helveticaMediumExtended";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
}

article h1 {
  margin: 1rem 0;
}

